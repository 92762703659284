import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';


interface Props extends PanelProps<SimpleOptions> {}

interface DataCardProps {
  name: string;
  value: string | number | boolean;
  timestamp: string;
}

const DataCard: React.FC<DataCardProps & { isMobile: boolean }> = ({ name, value, timestamp, isMobile }) => {

  const isBoolean = typeof value === 'boolean';
  const backgroundColor = isBoolean ? (value ? '#56a64b' : '#e02f44') : null;

  // If backgroundColor is set, change the text color to black
  const textColor = backgroundColor ? 'black' : 'inherit';

    // Format value if it's a number with decimal places
    const formattedValue = typeof value === 'number'
    ? (Number.isInteger(value) ? value : value.toFixed(2))
    : value;

  const cardStyle: React.CSSProperties = {
    border: '1px solid gray',
    margin: '5px',
    padding: '10px',
    width: isMobile ? 'calc(100% - 10px)' : 'auto', // Full width on mobile, auto on desktop
    boxSizing: 'border-box',
    overflowWrap: 'break-word',
    ...(backgroundColor && { backgroundColor }), // Apply backgroundColor only if it's set
    color: textColor,
  };


  const nameStyle: React.CSSProperties = {
    fontWeight: 'bold', // Make the font bold
    fontSize: '1.2em', // Increase the font size
  };

return (
    <div style={cardStyle}>
      <div style={nameStyle}>{name}</div>
      <div>{isBoolean ? value.toString() : formattedValue}</div>
    </div>
  );
};



export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [cards, setCards] = useState<DataCardProps[]>([]);
  const isMobile = width < 600;

  useEffect(() => {
    const newCards: DataCardProps[] = [];
    
    data.series.forEach((serie) => {
      const timeField = serie.fields.find(field => field.name === 'Time');
      
      if (timeField) {
        // Creating a card for the time field
        const lastTimestampIndex = timeField.values.length - 1;
        const lastTimestamp = lastTimestampIndex >= 0 ? timeField.values[lastTimestampIndex] : '';
  
        serie.fields.forEach((field) => {
          if (field.name !== 'Time') {
            const timestampIndex = lastTimestampIndex; // Use the same index for consistency
            const valueIndex = field.values.length - 1;
            const timestamp = timestampIndex >= 0 ? timeField.values[timestampIndex] : '';
            const value = valueIndex >= 0 ? field.values[valueIndex] : '';
            // Check if displayName exists in field.state, else use field.name
            const name = field.state?.displayName || field.name;
            newCards.push({ name, value, timestamp });
          }
        });
        newCards.push({ 
          name: 'Last Updated', 
          value: new Date(lastTimestamp ).toLocaleString(), 
          timestamp: lastTimestamp 
        });
      }
    });
  
    setCards(newCards);
  }, [data]);
  

  type StyleProps = React.CSSProperties;

  const cardContainerStyle: StyleProps = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'flex-start',
    overflowY: 'auto', // Enable vertical scrolling
    height: height, // Set the height of the container
    width: '100%',
    flexWrap: isMobile ? 'nowrap' : 'wrap', // Update based on layout requirement
  };

  return (
    <div style={cardContainerStyle}>
      {cards.map((card, index) => (
        <DataCard
          key={index}
          name={card.name}
          value={card.value}
          timestamp={card.timestamp}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};
